<template>
  <div>
    <div class="row g-3 mb-3">
      <div class="col-12 col-xl-12">
        <div class="row g-3">
          <div class="col-12">
            <div class="card bg-transparent-50 overflow-hidden">
              <div class="card-header position-relative">
                <div
                  class="bg-holder d-none d-md-block bg-card z-index-1"
                  style="
                    background-image: url('../assets/img/illustrations/ecommerce-bg.png');
                    background-size: 230px;
                    background-position: right bottom;
                    z-index: -1;
                  "
                ></div>
                <!--/.bg-holder-->
                <div class="position-relative z-index-2">
                  <div>
                    <h5 class="text-primary mb-1">
                      {{ getTimegreetings() }}, {{ user.first_name }}!
                    </h5>
                    <p>Here’s what happening with your store today</p>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item rounded-0">
                    <ul class="mb-0 list-unstyled">
                      <li
                        class="accordion-header alert mb-0 rounded-0 py-3 px-card alert-danger border-x-0 border-top-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                      >
                        <div class="row flex-between-center">
                          <div class="col">
                            <div class="d-flex">
                              <div class="fas fa-circle mt-1 fs--2"></div>
                              <p class="fs--1 ps-2 mb-0">
                                <strong>15 products</strong> are below the
                                replenish/refill level
                              </p>
                            </div>
                          </div>
                          <div class="col-auto d-flex align-items-center">
                            <a class="alert-link fs--1 fw-medium" href="e-commerce.html#!"
                              >View products<i class="fas fa-chevron-right ms-1 fs--2"></i
                            ></a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div
                      class="accordion-collapse collapse"
                      id="collapse1"
                      aria-labelledby="heading1"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <span class="badge rounded-pill badge-soft-primary"
                          >coming soon</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <ul class="mb-0 list-unstyled">
                      <li
                        id="heading2"
                        class="accordion-header alert mb-0 rounded-0 py-3 px-card alert-success border-x-0 border-top-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="true"
                        aria-controls="collapse2"
                      >
                        <div class="row flex-between-center">
                          <div class="col">
                            <div class="d-flex">
                              <div class="fas fa-circle mt-1 fs--2 text-primary"></div>
                              <p class="fs--1 ps-2 mb-0">
                                <strong>7 Products </strong> have been replenished today!
                              </p>
                            </div>
                          </div>
                          <div class="col-auto d-flex align-items-center">
                            <a class="alert-link fs--1 fw-medium" href="e-commerce.html#!"
                              >View products<i class="fas fa-chevron-right ms-1 fs--2"></i
                            ></a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div
                      class="accordion-collapse collapse"
                      id="collapse2"
                      aria-labelledby="heading2"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <span class="badge rounded-pill badge-soft-primary"
                          >coming soon</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <ul class="mb-n1 list-unstyled">
                      <li
                        id="heading3"
                        class="accordion-header alert mb-0 rounded-0 py-3 px-card alert-secondary border-x-0 border-top-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="true"
                        aria-controls="collapse3"
                      >
                        <div class="row flex-between-center">
                          <div class="col">
                            <div class="d-flex">
                              <div class="fas fa-circle mt-1 fs--2 text-primary"></div>
                              <p class="fs--1 ps-2 mb-0">
                                <strong>{{ inventory_trans.length }}+ products </strong>
                                have been used today
                              </p>
                            </div>
                          </div>
                          <div class="col-auto d-flex align-items-center">
                            <a class="alert-link fs--1 fw-medium" href="e-commerce.html#!"
                              >View orders<i class="fas fa-chevron-right ms-1 fs--2"></i
                            ></a>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div
                      class="accordion-collapse collapse"
                      id="collapse3"
                      aria-labelledby="heading3"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <!-- <h5>Used products</h5> -->
                        <!-- Menu items table  -->
                        <EasyDataTable
                          id="tour-step-1"
                          class="mx-0 my-0"
                          table-class-name="customize-table"
                          alternating
                          buttons-pagination
                          :headers="headers_used"
                          :items="inventory_trans"
                          :loading="loading"
                          @click-row="showRow"
                        >
                          <template #loading>
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </template>
                          <template #item-id="item">
                            <a class="text-secondary ms-2">
                              #<strong class="ml-1">{{ item.id }}</strong></a
                            >
                          </template>
                          <template #item-name="item">
                            <strong> {{ item.product.name }}</strong>
                          </template>
                          <template #item-owner="item">
                            <strong>{{ item.owner.first_name }}</strong>
                            {{ item.owner.last_name }}
                          </template>
                          <template #item-size="item">
                            <div class="address py-2 align-middle white-space-nowrap">
                              <p class="mb-0 text-900">
                                {{ item.size.title }}
                              </p>
                            </div>
                          </template>
                          <template #item-created_at="item">
                            <div class="address py-2 align-middle white-space-nowrap">
                              <p class="mb-0 text-900">
                                {{ formatDate(item.created_at) }}
                              </p>
                            </div>
                          </template>

                          <template #item-action="item">
                            <div class="dropdown font-sans-serif position-static">
                              <button
                                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                                type="button"
                                id="order-dropdown-0"
                                data-bs-toggle="dropdown"
                                data-boundary="viewport"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span class="fas fa-ellipsis-h fs--1"></span>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-end border py-0"
                                aria-labelledby="order-dropdown-0"
                              >
                                <div class="py-2">
                                  <a
                                    class="dropdown-item"
                                    type="button"
                                    data-bs-toggle="modal"
                                    :data-bs-target="'#staticBackdrop' + item.id"
                                  >
                                    Preview
                                  </a>
                                  <div class="dropdown-divider"></div>
                                  <a
                                    class="dropdown-item text-danger"
                                    role="button"
                                    @click="voidTicket(item)"
                                    >Delete</a
                                  >
                                </div>
                              </div>
                            </div>
                          </template>
                          <template #item-total_price="item">
                            <div class="align-middle text-right white-space-nowrap">
                              <p class="mb-0 fw-bold text-900">
                                {{ formatToCurrency(item.total_price) }}
                              </p>
                            </div>
                          </template>
                        </EasyDataTable>
                        <!-- End Menu items table  -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div
              class="card mb-3"
              id="ordersTable"
              data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
            >
              <div class="card-header">
                <div class="row flex-between-center border-bottom pb-1">
                  <div class="col-sm-4 col-xs-4 align-items-center pe-0">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                      <i class="fa fa-shopping-cart text-warning"></i>
                      Products
                    </h5>
                    <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                      Inventory menu items
                    </p>
                  </div>
                  <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
                    <div id="orders-actions" class="d-flex">
                      <button
                        class="btn btn-sm btn-falcon-warning ms-auto"
                        type="file"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Export product"
                        @click="exportData"
                      >
                        <i class="fas fa-file-excel"></i> Export
                      </button>
                      <button
                        class="btn btn-sm btn-rounded btn-falcon-warning ms-2"
                        type="button"
                        @click="addNew"
                        data-bs-toggle="modal"
                        data-bs-target="#addModal"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add new product"
                      >
                        <i class="fas fa-plus"></i>
                        New
                      </button>
                      <button
                        @click="forceReload"
                        class="btn btn-sm btn-falcon-warning ms-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Refresh page"
                      >
                        <i class="fa-solid fa-arrows-rotate"></i> Refresh
                      </button>
                      <!-- <button
                        @click="goToBarcode"
                        class="btn btn-sm btn-falcon-warning ms-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Open barcode page"
                      >
                        <i class="fa-solid fa-barcode"></i> Barcodes
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body px-3 py-0">
                <!-- Menu items table  -->
                <div
                  id="tableExample3"
                  data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
                >
                  <div class="row justify-content-start g-0">
                    <div class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3">
                      <select
                        class="form-select"
                        id="recipient-name"
                        type="text"
                        @change="selectOrderType"
                        style="width: auto"
                      >
                        <option
                          v-for="(item, i) in order_types"
                          :key="i"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3 d-flex ms-auto">
                      <div class="input-group ms-auto me-2">
                        <input
                          class="form-control form-control-sm shadow-none search"
                          type="search"
                          placeholder="Search..."
                          aria-label="search"
                          v-model="search_term"
                          v-on:keyup="searchItem"
                        />
                        <div class="input-group-text bg-transparent">
                          <span class="fa fa-search fs--1 text-600"></span>
                        </div>
                      </div>
                      <button class="btn btn-falcon-warning mx-1" @click="goToPOS">
                        POS
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive scrollbar">
                    <table class="table table-bordered table-striped fs--1 mb-0">
                      <thead class="bg-200 text-900">
                        <tr>
                          <th class="sort" data-sort="id" width="5%">Item #</th>
                          <th class="sort" data-sort="email">Photo</th>
                          <th class="sort" data-sort="age" width="20%">Name</th>
                          <th class="sort" data-sort="age">Quantities</th>
                          <!-- <th class="sort" data-sort="age">B.Price</th>
                          <th class="sort" data-sort="age">S.Price</th>
                          <th class="sort" data-sort="age">WS.Price</th> -->
                          <!-- <th class="sort" data-sort="age">Details</th> -->
                          <th class="sort" data-sort="age">Current Stock</th>
                          <th class="sort" data-sort="age">Units</th>
                          <th class="sort" data-sort="age">Register Usage</th>
                          <th class="sort" data-sort="age">Transfer</th>
                          <th class="sort" data-sort="age">Restock</th>
                          <!-- <th class="sort" data-sort="age">Action</th> -->
                        </tr>
                      </thead>
                      <tbody class="list">
                        <tr v-for="(item, i) in resources" :key="item.id">
                          <td>{{ item.id }}</td>
                          <td>
                            <div class="avatar avatar-xl">
                              <img
                                v-if="item.image"
                                class="rounded-circle"
                                :src="item.image"
                                alt="Product Image"
                                height="32"
                              />
                              <img
                                v-else
                                class="rounded-circle"
                                src="assets/img/no-image.jpg"
                                alt="Product Image"
                                height="32"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="my-2">
                              <strong>{{ item.name.toUpperCase() }}</strong>
                              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                            </div>
                          </td>
                          <td>
                            <button
                              v-for="(loc, i) in item.locations"
                              :key="i"
                              class="btn btn-sm btn-falcon-primary d-block fs--2 m-1"
                            >
                              {{ loc.name }} [{{ loc.pivot.quantity }}
                              {{ item.unit_name }}]
                            </button>
                          </td>
                          <td>
                            <button class="btn btn-sm btn-primary d-block">
                              {{ item.stock_amount }} {{ item.unit_name }}
                            </button>
                          </td>
                          <td>{{ item.unit_name }}</td>
                          <td>
                            <button
                              class="btn btn-falcon-warning btn-sm"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#register-usage-modal"
                              @click="itemModal(item)"
                            >
                              <i class="fa fa-check-circle"></i> Usage
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-falcon-primary btn-sm"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#transfer-modal"
                              @click="itemModal(item)"
                            >
                              <i class="fa fa-check-square"></i> Transfer
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-falcon-success btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#restock-modal"
                              @click="restockModal(item)"
                            >
                              <i class="fa fa-check-square"></i> Restock
                            </button>
                          </td>
                          <!-- <td>
                            <div class="dropdown font-sans-serif position-static">
                              <button
                                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                                type="button"
                                id="order-dropdown-0"
                                data-bs-toggle="dropdown"
                                data-boundary="viewport"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span class="fas fa-ellipsis-h fs--1"></span>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-end border py-0"
                                aria-labelledby="order-dropdown-0"
                              >
                                <div class="py-2">
                                  <a
                                    class="dropdown-item"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-modal"
                                    @click="editItem(item)"
                                  >
                                    Edit
                                  </a>
                                  <div class="dropdown-divider"></div>
                                  <a
                                    v-if="user.permissions.includes('void-ticket')"
                                    class="dropdown-item text-danger"
                                    role="button"
                                    @click="deleteResource(item)"
                                    >Delete</a
                                  >
                                </div>
                              </div>
                            </div>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-center mt-3">
                    <pagination
                      v-model="current_page"
                      :records="records"
                      :per-page="per_page"
                      @paginate="myCallback"
                    />
                  </div>
                </div>
                <!-- End Menu items table  -->
              </div>
              <div class="card-footer">
                <!-- footer content  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="register-usage-modal"
      :key="modals_key"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="my-2" id="modalExampleDemoLabel">Add a new usage</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="col-form-label" for="recipient-name"
                      >Product Name</label
                    >
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formData.name"
                      disabled
                    />
                  </div>
                  <div class="col-6 mt-3">
                    <label class="col-form-label" for="recipient-name">Location</label>
                    <select
                      class="form-select"
                      id="recipient-name"
                      placeholder="Select location..."
                      type="text"
                      v-model="formData.location_id"
                      :class="
                        v$.formData.location_id.$dirty && v$.formData.location_id.$invalid
                          ? `is-invalid`
                          : v$.formData.location_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option v-for="(item, i) in order_types" :key="i" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.location_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <label class="col-form-label" for="recipient-name">Quantity</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="number"
                        v-model="formData.quantity"
                        :class="
                          v$.formData.quantity.$dirty && v$.formData.quantity.$invalid
                            ? `is-invalid`
                            : v$.formData.quantity.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <span class="input-group-text" id="basic-addon2">{{
                        formData.unit_name
                      }}</span>
                    </div>
                    <div
                      v-for="error of v$.formData.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <label class="col-form-label" for="message-text">Message</label>
                  <textarea
                    class="form-control"
                    id="message-text"
                    v-model="formData.note"
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-warning ms-auto" type="button" @click="reduceStock">
              Reduce stock
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="transfer-modal"
      :key="modals_key"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="my-2" id="modalExampleDemoLabel">Transfer product</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="col-form-label" for="recipient-name"
                      >Product Name</label
                    >
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formDataTransfer.name"
                      disabled
                    />
                  </div>
                  <div class="col-6 mt-3">
                    <label class="col-form-label" for="recipient-name"
                      >Source Location</label
                    >
                    <select
                      class="form-select"
                      id="recipient-name"
                      placeholder="Select location..."
                      type="text"
                      v-model="formDataTransfer.from_location_id"
                      :class="
                        v$.formDataTransfer.from_location_id.$dirty && v$.formDataTransfer.from_location_id.$invalid
                          ? `is-invalid`
                          : v$.formDataTransfer.from_location_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option v-for="(item, i) in order_types" :key="i" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formDataTransfer.from_location_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <label class="col-form-label" for="recipient-name">Destination</label>
                    <select
                      class="form-select"
                      id="recipient-name"
                      placeholder="Select location..."
                      type="text"
                      v-model="formDataTransfer.to_location_id"
                      :class="
                        v$.formDataTransfer.to_location_id.$dirty && v$.formDataTransfer.to_location_id.$invalid
                          ? `is-invalid`
                          : v$.formDataTransfer.to_location_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option v-for="(item, i) in order_types" :key="i" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formDataTransfer.to_location_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <label class="col-form-label" for="recipient-name">Quantity</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="number"
                        v-model="formDataTransfer.quantity"
                        :class="
                          v$.formDataTransfer.quantity.$dirty && v$.formDataTransfer.quantity.$invalid
                            ? `is-invalid`
                            : v$.formDataTransfer.quantity.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <span class="input-group-text" id="basic-addon2">{{
                        formDataTransfer.unit_name
                      }}</span>
                    </div>
                    <div
                      v-for="error of v$.formDataTransfer.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <label class="col-form-label" for="message-text">Message</label>
                  <textarea
                    class="form-control"
                    id="message-text"
                    v-model="formDataTransfer.note"
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-warning ms-auto" type="button" @click="transferStock">
              Transfer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End new usage modal  -->
    <!-- Start restock modal  -->
    <div
      class="modal fade"
      id="restock-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Add a new restock</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="col-form-label" for="recipient-name"
                      >Product Name</label
                    >
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formData.name"
                      disabled
                    />
                  </div>
                  <div class="col-6 mt-3">
                    <label class="col-form-label" for="recipient-name">Location</label>
                    <select
                      class="form-select"
                      id="recipient-name"
                      placeholder="Select location..."
                      type="text"
                      v-model="formData.location_id"
                      :class="
                        v$.formData.location_id.$dirty && v$.formData.location_id.$invalid
                          ? `is-invalid`
                          : v$.formData.location_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option v-for="(item, i) in order_types" :key="i" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.location_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <label class="col-form-label" for="recipient-name">Quantity</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="number"
                        v-model="formData.quantity"
                        :class="
                          v$.formData.quantity.$dirty && v$.formData.quantity.$invalid
                            ? `is-invalid`
                            : v$.formData.quantity.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <span class="input-group-text" id="basic-addon2">{{
                        formData.unit_name
                      }}</span>
                    </div>
                    <div
                      v-for="error of v$.formData.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <label class="col-form-label" for="message-text">Message:</label>
                  <textarea
                    class="form-control"
                    id="message-text"
                    v-model="formData.note"
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-success ms-auto" type="button" @click="reStock">
              Restock
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End new usage modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import Choices from "choices.js";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import SimpleTypeahead from "vue3-simple-typeahead";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import ItemSizeService from "../services/item_size.service";
import MenuItemsService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import OrderTypeService from "../services/order_types.service";
import { required, email, minLength } from "@vuelidate/validators";
import MenuItemSizeService from "../services/menu_item_size.service";

export default {
  name: "InventoryDashboardView",
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      products: [],
      yValue: "",
      myOptions: ["op1", "op2", "op3"],
      per_page: 18,
      current_page: 0,
      records: 0,
      search_term: "",
      search_term: "",
      groups: [],
      resources: [],
      modals_key: 1,
      item_name: "",
      menu_items: [],
      item_sizes: [],
      order_type: "",
      searchValue: "",
      order_types: [],
      selected_items: [],
      menu_item_sizes: [],
      inventory_trans: [],
      import_resources: [],
      units: [
        {
          id: 1,
          name: "Pieces",
        },
      ],
      refreshed: 0,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        quantity: "",
        note: "",
        location_id: "",
      },
      formDataTransfer: {
        quantity: "",
        note: "",
        to_location_id: "",
        from_location_id: "",
      },
      searchField: ["id", "name", "description", "unit_name"],
      headers: [
        { text: "ITEM #", value: "id", sortable: true },
        { text: "NAME", value: "name", sortable: true },
        { text: "DESCRIPTION", value: "description", sortable: true },
        {
          text: "STOCK AMOUNT",
          value: "stock_amount",
          sortable: true,
        },
        { text: "UNIT NAME", value: "unit_name", sortable: true },
        { text: "VISIBLE", value: "visible", sortable: true },
        { text: "Action", value: "action" },
      ],
      headers_used: [
        { text: "TRANS #", value: "id", sortable: true },
        { text: "PRODUCT NAME", value: "name", sortable: true },
        { text: "USER", value: "owner", sortable: true },
        {
          text: "QUANTITY",
          value: "quantity",
          sortable: true,
        },
        { text: "UOM", value: "size", sortable: true },
        { text: "NOTE", value: "note", sortable: true },
        { text: "TRANS DATE", value: "created_at", sortable: true },
        { text: "Action", value: "action" },
      ],
      menu_item_names: [],
      myValue: "",
      myOptions: ["op1", "op2", "op3"],
    };
  },
  validations() {
    return {
      formData: {
        note: {},
        location_id: { required },
        quantity: { required },
      },
      formDataTransfer: {
        note: {},
        to_location_id: { required },
        from_location_id: { required },
        quantity: { required },
      },
    };
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.getAllOrderTypes();
    await this.getGroups();
    await this.getMenuItemSize();
    this.$store.commit("SET_LOADING", false);
    this.getMenuItems(1);

    // this.getInventoryTrans();
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    menu_item_names() {
      return this.menu_items.map((el) => el.name);
    },
    selected_item() {
      return this.menu_items.find((el) => el.name == this.item_name);
    },
  },
  watch: {
    menu_items: {
      hendler(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    itemModal(item) {
      this.formDataTransfer.note = "";
      this.formDataTransfer = item;
    },
    restockModal(item) {
      console.log(item);
      this.formData.note = "";
      this.formData = item;
    },
    searchItem() {
      this.page = 1;
      this.current_page = 1;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    myCallback(page) {
      this.page = page;
      this.getMenuItems(page);
    },
    async selectOrderType(e) {
      this.order_type = e.target.value;
      await this.getMenuItems(this.page);
      this.$store.commit("SET_LOADING", false);
    },
    getMenuItems(page) {
      this.$store.commit("SET_LOADING", true);
      console.log("ORDERTYPE", this.order_type);
      return MenuItemsService.byOrder(page, this.order_type).then(
        (response) => {
          if (response.data.code == 200) {
            console.log("resources", response);
            this.resources = response.data.data.data;
            this.products = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.order_type = response.data.data.length>0 ?  response.data.data.at(0).id : "";
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    updateSelected(e) {
      let item_id = event.target.value;
      if (event.target.checked) {
        this.selected.push(item_id);
      } else {
        this.selected = this.selected.filter((el) => el != item_id);
      }
      this.selected_items = this.selected.join(",");
    },
    reStock() {
      console.log(this.selected_item);
      this.formData.user_id = this.user.auto_id;
      this.formData.product_id = this.formData.id;
      this.formData.unit_name = this.formData.unit_name;
      this.v$.formData.$touch();
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      return MenuItemsService.restock(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Item updated successfully!");
            this.page = 1;
            this.getMenuItems(1);
            this.v$.formData.$reset();
          } else {
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    transferStock() {
      // if (!this.formData.from_location_id) {
      //   alert("Select source location!");
      //   return;
      // }
      // if (!this.formData.to_location_id) {
      //   alert("Select destination location!");
      //   return;
      // }
      this.formDataTransfer.user_id = this.user.auto_id;
      this.formDataTransfer.product_id = this.formDataTransfer.id;
      this.formDataTransfer.unit_name = this.formDataTransfer.unit_name;
      this.v$.formDataTransfer.$touch();
      if (this.v$.formDataTransfer.$errors.length > 0) {
        return;
      }
      console.log(this.formDataTransfer)
      return MenuItemsService.transfer(this.formDataTransfer).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Item updated successfully!");
            this.page = 1;
            this.getMenuItems(1);
            this.v$.formData.$reset();
          } else {
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    reduceStock() {
      this.formData.user_id = this.user.auto_id;
      this.formData.product_id = this.formData.id;
      this.formData.unit_name = this.formData.unit_name;
      this.v$.formData.$touch();
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      return MenuItemsService.reduce(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Item updated successfully!");
            this.page = 1;
            this.getMenuItems(1);
            this.v$.formData.$reset();
          } else {
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    itemSelected(e) {
      this.item_name = e.target.value;
      this.formData.product_id = this.menu_items.find(
        (el) => el.name == this.item_name
      ).id;
      console.log(e.target.value);
      this.v$.formData.product_id.$touch();
    },
    sizeSelected(e) {
      this.formData.size_id = e.target.value;
    },
    goToCustomers() {},
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getInventoryTrans() {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.inventoryTrans().then(
        (response) => {
          if (response.data.code == 200) {
            this.inventory_trans = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItemSize() {
      this.$store.commit("SET_LOADING", true);
      return ItemSizeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
